const CONFIG = {
    // 开发环境配置
    development: {
        baseUrl: 'http://127.0.0.1:9085', // 后台接口请求地址
        hostUrl: 'http://127.0.0.1:3000', // PC地址(前端运行地址)
    },
    // 生产环境配置
    production: {
        baseUrl: window._CONFIG.baseUrl ? window._CONFIG.baseUrl : 'http://api.jufeng88.com', // 后台接口请求地址
        hostUrl: 'http://121.199.47.110:8085', // PC地址(前端运行地址)
    }
};
export default CONFIG[process.env.NODE_ENV];
//export default CONFIG.production;
